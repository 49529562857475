import { useWeb3React } from '@pancakeswap/wagmi'
import { Collapse } from 'antd'
import styled from 'styled-components'
import orderBy from 'lodash/orderBy'
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  ArrowDropDownFooter,
  WinningCupIcon,
  ArrowDownIcon,
} from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useLocalDispatch from 'contexts/LocalRedux/useLocalDispatch'
import { Bet, HistoryFilter } from 'state/types'
import { fetchNodeHistory } from 'state/predictions'
import { useConfig } from 'views/Predictions/context/ConfigProvider'
import {
  useGetCurrentHistoryPage,
  useGetHasHistoryLoaded,
  useGetIsFetchingHistory,
  useGetHistoryFilter,
} from 'state/predictions/hooks'
import HistoricalBet from '../HistoricalBet'
import V1ClaimCheck from '../../v1/V1ClaimCheck'
import AllFilter from './AllFilter'
import UnCollectedFilter from './UnCollectedFilter'

const { Panel } = Collapse

const ResultText = styled(Text)`
  color: ${({ theme }) => theme.colors.success};
`

const CollectButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  margin-left: auto;
  border-radius: ${({ rounded }) => rounded || '8px'};
  color: ${({ textColor }) => textColor || 'white'};
  width: ${({ width }) => width || 'unset'};
  max-height: ${({ maxHeight }) => maxHeight || 'unset'};
`

const StyledHistoryBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.white10};
  border-radius: 24px;
`

const StyledHistory = styled.div`
  .ant-collapse {
    border: none !important;

    .ant-collapse-item {
      border: none;

      .ant-collapse-header {
        align-items: center !important;
      }

      & + .ant-collapse-item {
        border-top: 1px solid ${({ theme }) => theme.colors.white10};
      }

      .ant-collapse-content {
        border: none;

        .ant-collapse-content-box {
          background: ${({ theme }) => theme.colors.backgroundAlt};
          padding: 24px;
        }
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-expand-icon svg {
        transform: rotate(180deg);
      }
    }
  }
`

interface RoundsTabProps {
  hasBetHistory: boolean
  bets: Bet[]
}

const RoundsTab: React.FC<React.PropsWithChildren<RoundsTabProps>> = ({ hasBetHistory, bets }) => {
  const { t } = useTranslation()
  const dispatch = useLocalDispatch()
  const { account } = useWeb3React()
  const hasHistoryLoaded = useGetHasHistoryLoaded()
  const currentHistoryPage = useGetCurrentHistoryPage()
  const isFetchingHistory = useGetIsFetchingHistory()
  const { token } = useConfig()

  const handleClick = () => {
    dispatch(fetchNodeHistory({ account, page: currentHistoryPage + 1 }))
  }

  const v1Claim = token.symbol === 'BNB' ? <V1ClaimCheck /> : null
  const currentFilter = useGetHistoryFilter()

  let activeFilter = null

  switch (currentFilter) {
    case HistoryFilter.ALL:
      activeFilter = <AllFilter hasBetHistory={hasBetHistory} bets={bets} />
      break
    case HistoryFilter.COLLECTED:
      activeFilter = <AllFilter hasBetHistory={hasBetHistory} bets={bets} />
      break
    case HistoryFilter.UNCOLLECTED:
      activeFilter = <UnCollectedFilter hasBetHistory={hasBetHistory} bets={bets} />
      break
    default:
      activeFilter = <AllFilter hasBetHistory={hasBetHistory} bets={bets} />
  }

  return hasBetHistory ? (
    activeFilter
  ) : (
    <>
      {v1Claim}
      <Box p="24px" pt={0} pb="16px">
        <Heading size="lg" textAlign="center" mb="8px">
          {t('No prediction history available')}
        </Heading>
        <Text as="p" textAlign="center">
          {t("Make sure you're connected to the correct wallet to see the history.")}
        </Text>
      </Box>
    </>
  )
}

export default RoundsTab
