import {
  ModalContainer,
  ModalBody,
  Text,
  Button,
  InjectedModalProps,
  LinkExternal,
  Flex,
  Image,
  Heading,
  Checkbox,
  Box,
} from '@pancakeswap/uikit'
import styled from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'
import { useUserPredictionChartDisclaimerShow } from 'state/user/hooks'

const Ul = styled.ul`
  color: ${({ theme }) => theme.colors.textSubtle};
  list-style-position: outside;
  margin-left: 16px;

  & > li {
    font-size: 14px;
    line-height: 1.5;
  }
`

const ModalBody1 = styled(ModalBody)`
  @media screen and (max-width: 851px) {
    max-width: unset;
  }
`

const ChartDisclaimer: React.FC<React.PropsWithChildren<InjectedModalProps>> = ({ onDismiss }) => {
  const [showDisclaimer, setShowDisclaimer] = useUserPredictionChartDisclaimerShow()
  const { t } = useTranslation()

  const handleConfirm = () => {
    onDismiss?.()
  }

  return (
    <ModalContainer className="trading-view-modal" $minWidth="320px">
      <ModalBody1 p="24px" maxWidth="400px">
        <Flex justifyContent="center" mb="40px">
          <Image src="/images/predictions/chartwarning22.svg" width={183} height={75} />
        </Flex>

        <Text fontSize="16px" fontWeight={700}>
          {t('Warning')}: {t('Prices shown on cards and charts are different')}!
        </Text>
        <Text color="textSubtle" fontSize="12px" mt={2} mb="24px" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
          {t('The price you see comes from difference places')}:
        </Text>
        <Ul style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
          <li
            style={{
              fontSize: 12,
            }}
          >
            {t('Prices on cards come from Chainlink’s verifiable price oracle.')}
          </li>
          <li
            style={{
              fontSize: 12,
            }}
          >
            {t("Prices on charts come from Binance.com. Chart's are provided for your reference only.")}
          </li>
        </Ul>
        <Text as="p" mb="16px" fontSize="12px" color="textSubtle" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
          {t("Only the price from Chainlink (shown on the cards) determines the round's result.")}
        </Text>
        <LinkExternal href="https://docs.mummy.finance/prediction" external mb="16px" color="primary">
          {t('Learn More')}
        </LinkExternal>
        <Box>
          <Button width="100%" onClick={handleConfirm} mb="16px">
            {t('I understand')}
          </Button>
        </Box>
        <label htmlFor="checkbox" style={{ display: 'block', cursor: 'pointer' }}>
          <Flex alignItems="center">
            <div style={{ flex: 'none' }}>
              <Checkbox
                id="checkbox"
                scale="sm"
                checked={!showDisclaimer}
                onChange={() => setShowDisclaimer(!showDisclaimer)}
              />
            </div>
            <Text ml="8px">{t("Don't show this again")}</Text>
          </Flex>
        </label>
      </ModalBody1>
    </ModalContainer>
  )
}

export default ChartDisclaimer
