import { BoxProps, Flex, Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { BetPosition, Round } from 'state/types'
import { useTranslation } from '@pancakeswap/localization'
import { formatUsd } from '../History/helpers'
import PositionTag from '../PositionTag'
import { LockPriceHistoryRow, PrizePoolHistoryRow, RoundResultBox } from './styles'
import { useConfig } from '../../context/ConfigProvider'

interface RoundResultProps extends BoxProps {
  round: Round
  isOpenRound: boolean
  isLiveRound: boolean
}

const StyledRoundHistoryBox = styled(RoundResultBox)`
  padding: 1px;
  border-radius: 12px !important;

  > div {
    padding: 12px 16px;
    border-radius: 11px;
  }
`

const RoundResult: React.FC<React.PropsWithChildren<RoundResultProps>> = ({
  round,
  children,
  isOpenRound,
  isLiveRound,
  ...props
}) => {
  const { displayedDecimals } = useConfig()
  const { lockPrice, closePrice, totalAmount } = round
  // console.log('totalAmount', totalAmount)
  const betPosition = closePrice > lockPrice ? BetPosition.BULL : BetPosition.BEAR
  const isPositionUp = betPosition === BetPosition.BULL
  const { t } = useTranslation()
  const priceDifference = closePrice - lockPrice

  return (
    <StyledRoundHistoryBox betPosition={betPosition} {...props} style={{ height: 'auto' }}>
      <Text color="text" fontSize="14px" fontWeight={700} textTransform="uppercase" mb="16px">
        {t('Closed Price')}
      </Text>
      {round.failed ? (
        <Text bold textTransform="uppercase" color="textDisabled" mb="16px" fontSize="14px" style={{ fontWeight: 700 }}>
          {t('Cancelled')}
        </Text>
      ) : (
        <Flex alignItems="center" justifyContent="space-between" mb="16px">
          <Text color={isPositionUp ? 'success' : 'failure'} fontWeight={700} fontSize="20px">
            {isOpenRound || isLiveRound ? '$--' : formatUsd(Math.abs(closePrice), displayedDecimals)}
          </Text>
          <PositionTag hiddenArrow={isOpenRound || isLiveRound} style={{ height: '37px' }} betPosition={betPosition}>
            {isOpenRound || isLiveRound ? '$--' : formatUsd(Math.abs(priceDifference), displayedDecimals)}
          </PositionTag>
        </Flex>
      )}
      {lockPrice !== 0 && <LockPriceHistoryRow lockPrice={lockPrice} style={{ marginBottom: '16px' }} />}
      <PrizePoolHistoryRow totalAmount={totalAmount} style={{ marginBottom: '16px' }} />
      {children}
    </StyledRoundHistoryBox>
  )
}

export default RoundResult
