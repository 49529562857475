import {
  Card,
  CardBody,
  Flex,
  Spinner,
  WaitIcon,
  TooltipText,
  useTooltip,
  InfoIcon,
  CalculateOPXIcon,
  Box,
  Text,
} from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { NodeRound, BetPosition, NodeLedger } from 'state/types'
import useTheme from 'hooks/useTheme'
import styled, { keyframes } from 'styled-components'
import { RoundResultBox } from '../RoundResult'
import MultiplierArrow from './MultiplierArrow'
import CardHeader, { getBorderBackground } from './CardHeader'

interface CalculatingCardProps {
  round: NodeRound
  hasEnteredUp: boolean
  hasEnteredDown: boolean
  betAmount?: NodeLedger['amount']
}

const spinner = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`

const Loader = styled(Box)`
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  z-index: 2;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
    margin: -2px;
    background-image: linear-gradient(to right top, #07f5ae 50%, #00000000, #00000000);
    border-radius: inherit;
    animation: rotation 1s linear infinite;
    animation: ${spinner} 3s linear infinite;
  }
`

const StyledCalculateOPXIcon = styled(CalculateOPXIcon)`
  fill: none;
  position: absolute;
  inset: 0;
  background-color: #191b2e;
  border-radius: 50%;
  z-index: 2;
`

const CalculatingCard: React.FC<React.PropsWithChildren<CalculatingCardProps>> = ({
  round,
  hasEnteredUp,
  hasEnteredDown,
  betAmount,
}) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t('This round’s closing transaction has been submitted to the blockchain, and is awaiting confirmation.'),
    { placement: 'bottom' },
  )

  return (
    <>
      <Card borderBackground={getBorderBackground(theme, 'calculating')}>
        <CardHeader
          status="calculating"
          icon={<WaitIcon mr="4px" width="21px" />}
          title={t('Calculating')}
          epoch={round.epoch}
        />
        <CardBody p="16px">
          <MultiplierArrow isDisabled hasEntered={hasEnteredUp} betAmount={betAmount} />
          <RoundResultBox>
            <Flex alignItems="center" justifyContent="center" height="100%">
              {/* <Spinner size={96} /> */}
              <Flex mt="8px" ref={targetRef} flexDirection="column" alignItems="center">
                <Loader mb={16}>
                  <StyledCalculateOPXIcon />
                </Loader>
                <Flex>
                  <Text fontSize="16px">{t('Calculating')}</Text>
                  <InfoIcon ml="4px" width="24px" />
                </Flex>
              </Flex>
            </Flex>
          </RoundResultBox>
          <MultiplierArrow
            betPosition={BetPosition.BEAR}
            isDisabled
            hasEntered={hasEnteredDown}
            betAmount={betAmount}
          />
        </CardBody>
      </Card>
      {tooltipVisible && tooltip}
    </>
  )
}

export default CalculatingCard
