import { memo, useEffect, useRef, useState } from 'react'
import { useWeb3React } from '@pancakeswap/wagmi'
import styled, { css, keyframes } from 'styled-components'
import { Button, CloseIcon, IconButton, TrophyGoldIcon } from '@pancakeswap/uikit'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from '@pancakeswap/localization'
import { getBetHistory } from 'state/predictions/helpers'
import { useGetPredictionsStatus, useIsHistoryPaneOpen } from 'state/predictions/hooks'
import useLocalDispatch from 'contexts/LocalRedux/useLocalDispatch'
import { setHistoryPaneState } from 'state/predictions'
import { useConfig } from '../context/ConfigProvider'
import CollectWinningsButton from './CollectWinningsButton'
import usePollOraclePrice from '../hooks/usePollOraclePrice'

/**
 * @see https://github.com/animate-css/animate.css/tree/main/source
 */
const bounceInKeyframe = keyframes`
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0) scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  75% {
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`

const bounceOutKeyframe = keyframes`
  20% {
    transform: translate3d(0, 10px, 0) scaleY(0.985);
  }

  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0) scaleY(3);
  }
`

const bounceInAnimation = css`
  animation: ${bounceInKeyframe} 1s;
`

const bounceOutAnimation = css`
  animation: ${bounceOutKeyframe} 1s;
`

const Wrapper = styled.div`
  align-items: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  width: fit-content;
  position: absolute;
  z-index: 50;
  right: 0;

  &.popup-enter-active {
    ${bounceInAnimation}
  }

  &.popup-enter-done {
  }

  &.popup-exit-done {
    bottom: -2000px;
  }

  &.popup-exit-active {
    ${bounceOutAnimation}
  }

  ${({ theme }) => theme.mediaQueries.md} {
    bottom: 16px;
    justify-content: flex-end;

    &.popup-enter-done {
      bottom: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    bottom: 16px;
    width: 100%;
  }
`

const Popup = styled.div`
  align-items: center;
  /* background-color: ${({ theme }) => theme.colors.secondary}; */
  border-radius: 12px;
  color: #ffffff;
  display: flex;
  width: 300px;
  padding: 24px 16px;
  background: linear-gradient(90deg, #149274 0%, #00160e 100%);

  position: relative;

  .CloseIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;

    &:hover {
      path {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;

    & > button {
      margin-left: 12px;
    }
  }
`

const CollectWinningsPopup = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const ref = useRef(null)
  const timer = useRef(null)
  const { account } = useWeb3React()
  const predictionStatus = useGetPredictionsStatus()
  const isHistoryPaneOpen = useIsHistoryPaneOpen()
  // const dispatch = useLocalDispatch()
  const { api, token } = useConfig()
  const [bottom, setBottom] = useState(-100)

  const { price } = usePollOraclePrice()

  // const handleOpenHistory = () => {
  //   dispatch(setHistoryPaneState(true))
  // }

  const handleClick = () => {
    setIsOpen(false)
    clearInterval(timer.current)
  }

  // Check user's history for unclaimed winners
  useEffect(() => {
    let isCancelled = false
    if (account) {
      timer.current = setInterval(async () => {
        const bets = await getBetHistory(
          { user: account.toLowerCase(), claimed: false },
          undefined,
          undefined,
          api,
          token.symbol,
        )

        if (!isCancelled) {
          // Filter out bets that were not winners
          const winnerBets = bets.filter((bet) => {
            return bet.position === bet.round.position
          })

          if (!isHistoryPaneOpen) {
            setIsOpen(winnerBets.length > 0)
          }
        }
      }, 30000)
    }

    return () => {
      clearInterval(timer.current)
      isCancelled = true
    }
  }, [account, timer, predictionStatus, setIsOpen, isHistoryPaneOpen, api, token.symbol])

  // Any time the history pane is open make sure the popup closes
  useEffect(() => {
    if (isHistoryPaneOpen) {
      setIsOpen(false)
    }
  }, [isHistoryPaneOpen, setIsOpen])

  const heightOfAll = document.documentElement.scrollHeight
  useEffect(() => {
    const callback = () => {
      setBottom(heightOfAll - (window.scrollY + window.innerHeight) + 16) // 16px spacing
    }

    callback()

    window.addEventListener('scroll', callback)

    return () => {
      window.removeEventListener('scroll', callback)
    }
  }, [heightOfAll])

  return (
    <CSSTransition in={isOpen} unmountOnExit nodeRef={ref} timeout={1000} classNames="popup">
      <Wrapper ref={ref} style={{ bottom }}>
        <Popup>
          <TrophyGoldIcon mr="12px" />
          {/* <Button onClick={handleOpenHistory} width="200px">
            {t('Collect Winnings')}
          </Button> */}
          <CollectWinningsButton hasClaimed={false} width="200px" price={price || 0}>
            {t('Collect Winnings')}
          </CollectWinningsButton>
          <CloseIcon color="white20" width="16px" onClick={handleClick} className="CloseIcon" />
        </Popup>
      </Wrapper>
    </CSSTransition>
  )
}

export default memo(CollectWinningsPopup)
