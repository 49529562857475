import { useEffect, useState } from 'react'
import { useWeb3React } from '@pancakeswap/wagmi'
import { getPredictionsContract } from 'utils/contractHelpers'
import { BigNumber } from '@ethersproject/bignumber'
import { useConfig } from '../context/ConfigProvider'

const useLedger = (epoch: number) => {
  const [ledger, setLedger] = useState({
    amount: BigNumber.from(0),
    claimed: false,
  })
  const { account } = useWeb3React()
  const { address } = useConfig()

  useEffect(() => {
    const clear = setInterval(() => {
      const fetchRefundableStatus = async () => {
        const predictionsContract = getPredictionsContract(address)
        const { claimed, amount } = await predictionsContract.ledger(epoch, account)

        setLedger({ amount, claimed })
      }

      if (account) {
        fetchRefundableStatus()
      }
    }, 5000)
    return () => clearInterval(clear)
  }, [account, address, epoch])

  return { ledger, setLedger }
}

export default useLedger
