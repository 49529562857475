import { useState, useCallback } from 'react'
import {
  ModalContainer,
  ModalBody,
  Text,
  Button,
  Flex,
  InjectedModalProps,
  Checkbox,
  ModalHeader,
  ModalTitle,
  Heading,
  Box,
} from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import styled, { useTheme } from 'styled-components'

interface CheckType {
  key: string
  value?: boolean
  content: string
}

interface RiskDisclaimerProps extends InjectedModalProps {
  onSuccess: () => void
  checks: CheckType[]
  header: string
  id: string
  subtitle?: string
}

const StyledModalHeading = styled(Heading)`
  font-size: 16px;
  font-weight: 700;
`

const GradientModalHeader = styled(ModalHeader)`
  background: ${({ theme }) => theme.colors.secondary};
  padding-bottom: 24px;
  padding-top: 24px;
  border: none;
  height: 59px;
`

// TODO: Copy from src/views/Predictions/components/RiskDisclaimer.tsx
// Will replace that with this modal.

const DisclaimerModal: React.FC<React.PropsWithChildren<RiskDisclaimerProps>> = ({
  id,
  onSuccess,
  onDismiss,
  checks,
  header,
  subtitle,
}) => {
  const [checkState, setCheckState] = useState(checks || [])
  const { t } = useTranslation()
  const theme = useTheme()

  const handleSetAcknowledgeRisk = useCallback(
    (currentKey) => {
      const newCheckState = checkState.map((check) => {
        if (currentKey === check.key) {
          return { ...check, value: !check.value }
        }

        return check
      })

      setCheckState(newCheckState)
    },
    [checkState],
  )

  const handleConfirm = useCallback(() => {
    onSuccess()
    onDismiss()
  }, [onSuccess, onDismiss])

  return (
    <ModalContainer className="modal-welcome" title={t('Welcome!')} $minWidth="320px" id={id}>
      <ModalHeader background={theme.colors.primary}>
        <ModalTitle color="textMain">{t('Welcome')}</ModalTitle>
      </ModalHeader>
      <ModalBody p="16px 24px 24px" maxWidth={['100%', '100%', '100%', '400px']}>
        <Box maxHeight="300px" overflowY="auto">
          <Heading as="h3" mb="8px" style={{ fontSize: '16px', fontWeight: 500 }}>
            {header}
          </Heading>
          {subtitle && (
            <Text as="p" color="textSubtle" mb="16px" style={{ fontSize: '12px' }}>
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: 'rgba(255, 255, 255, 0.6)',
                }}
              >
                {subtitle}
              </span>
            </Text>
          )}
          {checkState.map((check) => (
            <label
              key={check.key}
              htmlFor={check.key}
              style={{ display: 'block', cursor: 'pointer', marginBottom: '24px' }}
            >
              <Flex alignItems="center">
                <div style={{ flex: 'none', alignSelf: 'flex-start' }}>
                  <Checkbox
                    className={`${check.value ? 'checked' : ''}`}
                    id={check.key}
                    scale="sm"
                    checked={check.value}
                    onChange={() => handleSetAcknowledgeRisk(check.key)}
                  />
                </div>
                <Text ml="4px">{check.content}</Text>
              </Flex>
            </label>
          ))}
        </Box>
        <Button
          id={`${id}-continue`}
          width="100%"
          onClick={handleConfirm}
          disabled={checkState.some((check) => !check.value)}
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            {t('Confirm')}
          </span>
        </Button>
      </ModalBody>
    </ModalContainer>
  )
}

export default DisclaimerModal
