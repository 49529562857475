import styled from 'styled-components'
import Link from 'next/link'
import { Flex, HelpIcon, Button, ShieldIcon, Box } from '@pancakeswap/uikit'
import { useGetPredictionsStatus } from 'state/predictions/hooks'
import { PredictionStatus } from 'state/types'
import FlexRow from './FlexRow'
import { PricePairLabel, TimerLabel } from './Label'
import PrevNextNav from './PrevNextNav'
import HistoryButton from './HistoryButton'

const SetCol = styled(Box)`
  flex: none;
  min-width: 324px;

  /* ${({ theme }) => theme.mediaQueries.lg} {
    width: 270px;
  } */
`

const PriceCol = styled(SetCol)`
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.tertiary};
  height: 50px;
  width: 100%;
  max-width: 324px;

  @media (max-width: 739px) {
    max-width: unset;
  }
`
const TimeWrap = styled(Flex)`
  @media screen and (max-width: 767px) {
    margin-left: 0;
    width: 100%;
  }
`

const HelpButtonWrapper = styled.div`
  order: 1;
  margin: 0 2px 0 10px;
  background: ${({ theme }) => theme.colors.cardHeaderBg};
  border: 1px solid ${({ theme }) => theme.colors.tertiary};
  border-radius: 8px;

  ${({ theme }) => theme.mediaQueries.sm} {
    order: 2;
    margin: 0 0 0 10px;
  }
`

const TimerLabelWrapper = styled.div`
  order: 1;
  flex: 1;
  /* width: 100px; */

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
  }
`

const LeaderboardButtonWrapper = styled.div`
  display: block;

  order: 2;
  margin: 0 0 0 10px;
  background: ${({ theme }) => theme.colors.cardHeaderBg};
  border: 1px solid ${({ theme }) => theme.colors.tertiary};
  border-radius: 8px;

  ${({ theme }) => theme.mediaQueries.sm} {
    order: 3;
  }
`

const ButtonWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: block;
    margin-left: 10px;
  }
`

const StyledTopMenu = styled(FlexRow)`
  padding-top: 48px;
  padding-bottom: 48px;

  @media (max-width: 739px) {
    flex-direction: column;
    align-items: stretch;
    padding: 16px;
    justify-content: space-between;
    gap: 16px;
  }
`

const StyledPrevNextNavWrapper = styled(FlexRow)`
  @media (max-width: 739px) {
    display: none;
  }
`

const Menu = () => {
  const status = useGetPredictionsStatus()

  return (
    <StyledTopMenu alignItems="center" p="16px" width="100%">
      {status === PredictionStatus.LIVE && (
        <PriceCol>
          <PricePairLabel />
        </PriceCol>
      )}
      {status === PredictionStatus.LIVE && (
        <>
          <StyledPrevNextNavWrapper justifyContent="center">
            <PrevNextNav />
          </StyledPrevNextNavWrapper>
          <SetCol>
            <TimeWrap alignItems="stretch" justifyContent="flex-end" height="50px" width="fit-content" ml="auto">
              <TimerLabelWrapper>
                <TimerLabel interval="5" unit="m" />
              </TimerLabelWrapper>
              <HelpButtonWrapper>
                <Button
                  variant="text"
                  as="a"
                  href="https://docs.mummy.finance/prediction"
                  target="_blank"
                  rel="noreferrer noopener"
                  width="50px"
                  height="100%"
                >
                  <HelpIcon width="24px" color="white" />
                </Button>
              </HelpButtonWrapper>
              <LeaderboardButtonWrapper>
                <Button as="a" href="prediction/leaderboard" variant="text" width="50px" height="100%">
                  <ShieldIcon color="white" />
                </Button>
              </LeaderboardButtonWrapper>
              {/* <LeaderboardButtonWrapper>
                <Link href="/prediction/leaderboard" passHref>
                  <Button as="a" variant="tertiary" width="50px">
                    <ShieldIcon color="white" />
                  </Button>
                </Link>
              </LeaderboardButtonWrapper> */}
              <ButtonWrapper style={{ order: 4 }}>
                <HistoryButton />
              </ButtonWrapper>
            </TimeWrap>
          </SetCol>
        </>
      )}
    </StyledTopMenu>
  )
}

export default Menu
