import { ArrowBackIcon, ArrowForwardIcon, BunnyCardsIcon, Flex, IconButton } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { useGetSortedRoundsCurrentEpoch } from 'state/predictions/hooks'
import useSwiper from '../hooks/useSwiper'

const StyledPrevNextNav = styled(Flex)`
  align-items: center;
  display: none;
  justify-content: space-between;
  overflow: initial;
  position: relative;
  height: 40px;
  width: 184px;
  padding: 4px;

  box-shadow: ${({ theme }) => theme.shadows.level1};
  border-radius: ${({ theme }) => theme.radii.full};
  /* background-color: ${({ theme }) => theme.card.background}; */

  background-color: ${({ theme }) => theme.colors.backgroundAlt};

  .Button-Arrow {
    &:hover {
      opacity: 1;
    }

    &.next {
      svg {
        rotate: 180deg;
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    display: flex;
  }
`

const Icon = styled.div`
  left: 50%;
  margin-left: -32px;
  position: absolute;
`

const PrevNextNav = () => {
  const { swiper } = useSwiper()
  const { currentEpoch, rounds } = useGetSortedRoundsCurrentEpoch()

  const handlePrevSlide = () => {
    swiper?.slidePrev()
  }

  const handleNextSlide = () => {
    swiper?.slideNext()
  }

  const handleSlideToLive = () => {
    if (swiper) {
      const currentEpochIndex = rounds.findIndex((round) => round.epoch === currentEpoch)
      swiper.slideTo(currentEpochIndex - 1)
    }
  }

  return (
    <StyledPrevNextNav>
      <IconButton className="Button-Arrow" variant="text" scale="sm" onClick={handlePrevSlide}>
        <ArrowBackIcon color="white" width="24px" />
      </IconButton>
      <Icon onClick={handleSlideToLive}>
        <BunnyCardsIcon width="64px" />
      </Icon>
      <IconButton className="Button-Arrow next" variant="text" scale="sm" onClick={handleNextSlide}>
        <ArrowBackIcon color="white" width="24px" />
      </IconButton>
    </StyledPrevNextNav>
  )
}

export default PrevNextNav
