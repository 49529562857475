import { ReactElement } from 'react'
import { Flex, Text } from '@pancakeswap/uikit'
import styled, { DefaultTheme } from 'styled-components'

type Status = 'expired' | 'live' | 'next' | 'soon' | 'canceled' | 'calculating'

interface CardHeaderProps {
  status: Status
  title: string
  epoch: number
  icon?: ReactElement
}

const HEADER_HEIGHT = '65px'

// Used to get the gradient for the card border, which depends on the header color to create the illusion
// that header is overlapping the 1px card border.
// 'live' is not included into the switch case because it has isActive border style
export const getBorderBackground = (theme: DefaultTheme, status: Status) => {
  const gradientStopPoint = `calc(${HEADER_HEIGHT} + 1px)`
  switch (status) {
    case 'calculating':
      return `linear-gradient(transparent ${gradientStopPoint}, ${theme.colors.cardBorder} ${gradientStopPoint}), ${theme.colors.gradientCardHeader}`
    case 'canceled':
      return `linear-gradient(${theme.colors.warning} ${gradientStopPoint}, ${theme.colors.cardBorder} ${gradientStopPoint})`
    case 'next':
      return `linear-gradient(${theme.colors.secondary} ${gradientStopPoint}, ${theme.colors.cardBorder} ${gradientStopPoint})`
    case 'expired':
    case 'soon':
    default:
      return theme.colors.cardBorder
  }
}

const getBackgroundColor = (theme: DefaultTheme, status: Status) => {
  switch (status) {
    case 'canceled':
      return theme.colors.warning
    case 'next':
      return theme.colors.success
    case 'calculating':
    case 'expired':
    case 'soon':
    case 'live':
      return theme.colors.cardHeaderBg
    default:
      return theme.colors.cardBorder
  }
}

type TextColor = 'textDisabled' | 'white' | 'secondary' | 'text' | 'textSubtle' | 'textMain' | 'white60' | 'primary'
type FallbackColor = 'text' | 'textSubtle'

const getTextColorByStatus = (status: Status, fallback: FallbackColor): TextColor => {
  switch (status) {
    case 'expired':
      return 'white60'
    case 'next':
    case 'canceled':
      return 'textMain'
    case 'live':
      return 'primary'
    case 'calculating':
      return 'text'
    case 'soon':
      return 'white'
    default:
      return fallback
  }
}

const StyledCardHeader = styled.div<{ status: Status }>`
  align-items: center;
  background: ${({ theme, status }) => getBackgroundColor(theme, status)};
  display: flex;
  justify-content: space-between;
  height: ${HEADER_HEIGHT};
  padding: ${({ status }) => (status === 'live' ? '16px' : '8px')};
  padding: 16px 24px;
`

const Round = styled.div`
  justify-self: center;
`

const CardHeader: React.FC<React.PropsWithChildren<CardHeaderProps>> = ({ status, title, epoch, icon }) => {
  const textColor = getTextColorByStatus(status, 'text')
  // const isLive = status === 'live'

  return (
    <StyledCardHeader status={status}>
      <Flex alignItems="center">
        <Flex alignItems="center" className="icon">
          {icon}
        </Flex>
        <Text color={textColor} fontWeight={700} lineHeight="21px">
          {title}
        </Text>
      </Flex>
      <Round>
        <Text fontWeight={500} color={getTextColorByStatus(status, 'textSubtle')} textAlign="center">
          {`#${epoch}`}
        </Text>
      </Round>
    </StyledCardHeader>
  )
}

export default CardHeader
