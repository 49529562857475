import { ReactNode } from 'react'
import styled from 'styled-components'
import {
  ArrowUpIcon,
  ArrowDownIcon,
  Flex,
  FlexProps,
  Text,
  useTooltip,
  TooltipText,
  InfoIcon,
} from '@pancakeswap/uikit'
import { BetPosition } from 'state/types'
import { useTranslation } from '@pancakeswap/localization'

interface TagProps extends FlexProps {
  startIcon?: ReactNode
  textColor: string
  hiddenArrow?: boolean
}

const StyledTag = styled(Flex)`
  display: inline-flex;
`

export const Tag: React.FC<React.PropsWithChildren<TagProps>> = ({
  bg = 'success',
  startIcon,
  textColor,
  children,
  onClick,
  hiddenArrow,
  ...props
}) => {
  const icon = startIcon || <ArrowUpIcon color="black" />

  return (
    <StyledTag
      alignItems="center"
      justifyContent="center"
      borderRadius="8px"
      bg={bg}
      py="4px"
      px="8px"
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'normal' }}
      {...props}
    >
      {!hiddenArrow && icon}
      <Text fontWeight={700} textTransform="uppercase" color={textColor} ml="4px">
        {children}
      </Text>
    </StyledTag>
  )
}

interface PositionTagProps extends FlexProps {
  betPosition: BetPosition
  hiddenArrow?: boolean
}

const PositionTag: React.FC<React.PropsWithChildren<PositionTagProps>> = ({
  betPosition,
  children,
  hiddenArrow,
  ...props
}) => {
  const { t } = useTranslation()
  const isUpPosition = betPosition === BetPosition.BULL
  const icon = isUpPosition ? (
    <ArrowUpIcon height="16px" color="black" />
  ) : (
    <ArrowDownIcon height="16px" color="white" />
  )
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    <>
      <Text mb="4px">{t('Neither position wins this round.')}</Text>
      <Text>
        {t(
          'The locked and closed prices are identical (within 8 decimal places), so ALL funds invested in both positions go to the Treasury.',
        )}
      </Text>
    </>,
    { placement: 'top' },
  )

  if (betPosition === BetPosition.HOUSE) {
    return (
      <>
        {tooltipVisible && tooltip}
        <TooltipText ref={targetRef} color="secondary" fontWeight="300" textTransform="uppercase">
          <Flex alignItems="center">
            <span style={{ color: '#ffffff' }}>{t('To Treasury')}</span>
            <InfoIcon width="16px" ml="4px" color="white" />
          </Flex>
        </TooltipText>
      </>
    )
  }

  return (
    <Tag
      bg={isUpPosition ? 'success' : 'failure'}
      startIcon={!hiddenArrow && icon}
      hiddenArrow={hiddenArrow}
      {...props}
      textColor={isUpPosition ? 'black' : 'white'}
    >
      <Text fontSize="14px" color={isUpPosition ? 'black' : 'white'} fontWeight={700}>
        {children}
      </Text>
    </Tag>
  )
}

export default PositionTag
