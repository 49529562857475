import styled from 'styled-components'
import Web3 from 'web3'
import { useWeb3React } from '@pancakeswap/wagmi'
import { Box, Flex, Heading, Text, Button, Link, OpenNewIcon } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { getRoundResult, Result } from 'state/predictions/helpers'
import { REWARD_RATE } from 'state/predictions/config'
import { getBlockExploreLink } from 'utils'
import { multiplyPriceByAmount } from 'utils/prices'
import useBUSDPrice from 'hooks/useBUSDPrice'
import { useGetCurrentEpoch } from 'state/predictions/hooks'
import { Bet, BetPosition } from 'state/types'
import { useConfig } from 'views/Predictions/context/ConfigProvider'

import { arrayURLFetcher } from 'utils/legacy'
import usePollOraclePrice from 'views/Predictions/hooks/usePollOraclePrice'
import { formatBnb, formatUsdPro, getMultiplier, getNetPayout } from '../helpers'
import PnlChart from './PnlChart'
import SummaryRow from './SummaryRow'

interface PnlTabProps {
  hasBetHistory: boolean
  bets: Bet[]
}

interface PnlCategory {
  rounds: number
  amount: number
}

interface PnlSummary {
  won: PnlCategory & { payout: number; bestRound: { id: string; payout: number; multiplier: number } }
  lost: PnlCategory
  entered: PnlCategory
}

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.cardHeaderBg};
  height: 1px;
  margin: 24px auto;
  width: 100%;
`

const initialPnlSummary: PnlSummary = {
  won: {
    rounds: 0,
    amount: 0,
    payout: 0, // net payout after all deductions
    bestRound: {
      id: '0',
      payout: 0, // net payout after all deductions
      multiplier: 0,
    },
  },
  lost: {
    rounds: 0,
    amount: 0,
  },
  entered: {
    rounds: 0,
    amount: 0,
  },
}

const WonButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  gap: 8px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.failure};
`

const getPnlSummary = (bets: Bet[], currentEpoch: number): PnlSummary => {
  return bets.reduce((summary: PnlSummary, bet) => {
    const roundResult = getRoundResult(bet, currentEpoch)
    if (roundResult === Result.WIN) {
      const payout = getNetPayout(bet, REWARD_RATE)
      let { bestRound } = summary.won
      if (payout > bestRound.payout) {
        const { bullAmount, bearAmount, totalAmount } = bet.round
        const multiplier = getMultiplier(totalAmount, bet.position === BetPosition.BULL ? bullAmount : bearAmount)
        bestRound = { id: bet.round.epoch.toString(), payout, multiplier }
      }
      return {
        won: {
          rounds: summary.won.rounds + 1,
          amount: summary.won.amount + bet.amount,
          payout: summary.won.payout + payout,
          bestRound,
        },
        entered: {
          rounds: summary.entered.rounds + 1,
          amount: summary.entered.amount + bet.amount,
        },
        lost: summary.lost,
      }
    }
    if (roundResult === Result.LOSE || roundResult === Result.HOUSE) {
      return {
        lost: {
          rounds: summary.lost.rounds + 1,
          amount: summary.lost.amount + bet.amount,
        },
        entered: {
          rounds: summary.entered.rounds + 1,
          amount: summary.entered.amount + bet.amount,
        },
        won: summary.won,
      }
    }
    // Ignore Canceled and Live rounds
    return summary
  }, initialPnlSummary)
}

const PnlTab: React.FC<React.PropsWithChildren<PnlTabProps>> = ({ hasBetHistory, bets }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const currentEpoch = useGetCurrentEpoch()
  const { token, displayedDecimals } = useConfig()
  const { price } = usePollOraclePrice()

  const bnbBusdPrice = Number(Web3.utils.fromWei(price.toString())) * 10e9
  // const bnbBusdPrice = tokenInfo?.[0]?.OPX?.price || 0
  // console.log('price OP', bnbBusdPrice)

  const summary = getPnlSummary(bets, currentEpoch)

  const netResultAmount = summary.won.payout - summary.lost.amount
  const netResultIsPositive = netResultAmount > 0
  const avgPositionEntered = summary.entered.amount / summary.entered.rounds
  const avgBnbWonPerRound = netResultAmount / summary.entered.rounds
  const avgBnbWonIsPositive = avgBnbWonPerRound > 0

  // Guard in case user has only lost rounds
  const hasBestRound = summary.won.bestRound.payout !== 0

  const netResultInUsd = bnbBusdPrice * Number(netResultAmount)
  const avgBnbWonInUsd = bnbBusdPrice * Number(avgBnbWonPerRound)
  const avgBnbWonInUsdDisplay = !Number.isNaN(avgBnbWonInUsd) ? `${formatUsdPro(Math.abs(avgBnbWonInUsd), 2)}` : '$0.00'
  const betRoundInUsd = bnbBusdPrice * Number(summary.won.bestRound.payout)
  const avgPositionEnteredInUsd = bnbBusdPrice * Number(avgPositionEntered)
  const avgPositionEnteredInUsdDisplay = !Number.isNaN(avgPositionEnteredInUsd)
    ? `${formatUsdPro(Math.abs(avgPositionEnteredInUsd), 2)}`
    : '$0.00'

  // console.log('hasBetHistory', hasBetHistory)

  return hasBetHistory ? (
    <Box p="24px" pt={0} pb="16px">
      <Text bold fontSize="16px" color="white" fontWeight={700} pb="12px">
        {t('Your history')}
      </Text>
      <Flex>
        <PnlChart lost={summary.lost.rounds} won={summary.won.rounds} />
        <Flex flexDirection="column" justifyContent="center" pl="16px">
          <Text fontWeight={700} fontSize="14px">
            {t('Net results')}
          </Text>
          <Text fontWeight={700} fontSize="24px" lineHeight="36px" color={netResultIsPositive ? 'success' : 'failure'}>
            {`${netResultIsPositive ? '+' : ''}${formatBnb(netResultAmount, 2)} FTM`}
          </Text>
          <Text fontSize="12px" color="white60">{`${formatUsdPro(Math.abs(netResultInUsd), 2)}`}</Text>
        </Flex>
      </Flex>
      <Box>
        <Text mt="24px" fontSize="14px">
          {t('Average return / round')}
        </Text>
        <Text my="4px" fontWeight={700} color={avgBnbWonIsPositive ? 'success' : 'failure'}>
          {`${avgBnbWonIsPositive ? '+' : ''}${formatBnb(avgBnbWonPerRound, 2)} FTM`}
        </Text>
        <Text fontSize="12px" color="white60">
          {avgBnbWonInUsdDisplay}
        </Text>

        {hasBestRound && (
          <>
            <Text mt="16px" fontSize="14px">
              {t('Best round: #%roundId%', { roundId: summary.won.bestRound.id })}
            </Text>
            <Flex alignItems="center" my="4px">
              <Text fontWeight={700} fontSize="14px" color="primary">{`+${formatBnb(
                summary.won.bestRound.payout,
                2,
              )} FTM`}</Text>
              <Text ml="4px" fontWeight={400} fontSize="12px">
                ({summary.won.bestRound.multiplier.toFixed(2)}x)
              </Text>
            </Flex>
            <Text fontSize="12px" color="white60">
              {`$${betRoundInUsd.toFixed(2)}`}
            </Text>
          </>
        )}

        <Text mt="16px" fontSize="14px">
          {t('Average position entered / round')}
        </Text>
        <Text my="4px" fontWeight={700} fontSize="14px">{`${formatBnb(avgPositionEntered, 2)} FTM`}</Text>
        <Text fontSize="12px" color="white60">
          {avgPositionEnteredInUsdDisplay}
        </Text>

        <Divider />

        <SummaryRow type="won" summary={summary} bnbBusdPrice={bnbBusdPrice} />
        <SummaryRow type="lost" summary={summary} bnbBusdPrice={bnbBusdPrice} />
        <SummaryRow type="entered" summary={summary} bnbBusdPrice={bnbBusdPrice} />

        <Flex mt="24px" justifyContent="center" alignItems="stretch">
          <Button
            as={Link}
            href={`${getBlockExploreLink(account, 'address')}#internaltx`}
            external
            scale="md"
            variant="success"
            style={{ fontSize: 14, width: '100%' }}
          >
            {t('View Reclaimed & Won')}
          </Button>
          {/* <WonButton mt="8px" width="100%" p="7.5px 9.5px">
              <OpenNewIcon ml="4px" width="16px" />
            </WonButton> */}
        </Flex>
      </Box>
    </Box>
  ) : (
    <Box p="24px" pt={0} pb="16px">
      <Heading size="lg" textAlign="center" mb="8px">
        {t('No prediction history available')}
      </Heading>
      <Text as="p" textAlign="center">
        {t("Make sure you're connected to the correct wallet to see the history.")}
      </Text>
    </Box>
  )
}

export default PnlTab
