import { useTranslation } from '@pancakeswap/localization'
import { BlockIcon, Box, Card, CardBody, Flex, LogoOpx } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'
import { getHasRoundFailed } from 'state/predictions/helpers'
import { useCollectWinningModalProps, useGetBufferSeconds, useGetIsClaimable } from 'state/predictions/hooks'
import { BetPosition, NodeLedger, NodeRound } from 'state/types'
import styled from 'styled-components'
import usePollOraclePrice from 'views/Predictions/hooks/usePollOraclePrice'
import { getRoundPosition } from '../../helpers'
import CollectWinningsButton from '../CollectWinningsButton'
import { RoundResult } from '../RoundResult'
import CalculatingCard from './CalculatingCard'
import CanceledRoundCard from './CanceledRoundCard'
import CardHeader, { getBorderBackground } from './CardHeader'
import MultiplierArrow from './MultiplierArrow'

interface ExpiredRoundCardProps {
  round: NodeRound
  betAmount?: NodeLedger['amount']
  hasEnteredUp: boolean
  hasEnteredDown: boolean
  hasClaimedUp: boolean
  hasClaimedDown: boolean
  bullMultiplier: string
  bearMultiplier: string
  isActive?: boolean
}

const StyledExpiredRoundCard = styled(Card)`
  /* opacity: 0.7; */
  transition: opacity 300ms;
  &:before {
    z-index: 10;
    content: '';
    position: absolute;

    opacity: 0.7;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    /* background: url('http://img42.imageshack.us/img42/1893/96c75664f7e94f9198ad113.png') no-repeat 0 0;  */
  }
  &:hover {
    opacity: 1;
  }
  .card-enter-postition-down {
    bottom: 0px;
    top: unset;
  }
`

const CollectWinning = styled(Flex)`
  padding: 20px 24px;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  gap: 24px;
  z-index: 10000;
  background: ${({ theme }) => theme.colors.prize};

  height: fit-content;
  bottom: 0;

  > button {
    flex: 1;
    height: 36px;
    color: black;
  }
`

const ExpiredRoundCard: React.FC<React.PropsWithChildren<ExpiredRoundCardProps>> = ({
  round,
  betAmount,
  hasEnteredUp,
  hasEnteredDown,
  hasClaimedUp,
  hasClaimedDown,
  bullMultiplier,
  bearMultiplier,
  isActive,
}) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { epoch, lockPrice, closePrice } = round
  const betPosition = getRoundPosition(lockPrice, closePrice)
  const bufferSeconds = useGetBufferSeconds()
  const { price } = usePollOraclePrice()

  const { history, isLoadingHistory } = useCollectWinningModalProps()
  const hasRoundFailed = getHasRoundFailed(round.oracleCalled, round.closeTimestamp, bufferSeconds)

  const isClaimable = useGetIsClaimable(epoch)

  if (hasRoundFailed) {
    return <CanceledRoundCard round={round} />
  }

  if (!closePrice) {
    return (
      <CalculatingCard
        round={round}
        hasEnteredDown={hasEnteredDown}
        hasEnteredUp={hasEnteredUp}
        betAmount={betAmount}
      />
    )
  }

  const cardProps = isActive
    ? {
        isActive,
      }
    : {
        borderBackground: getBorderBackground(theme, 'expired'),
      }

  // useEffect(() => {
  //   onPresentClaimModal();
  // })
  return (
    <Box position="relative">
      <StyledExpiredRoundCard {...cardProps}>
        <CardHeader
          status="expired"
          icon={<BlockIcon mr="4px" width="21px" color="white60" />}
          title={t('Expired')}
          epoch={round.epoch}
        />
        <CardBody p="16px" style={{ position: 'relative' }}>
          {isClaimable && (
            <CollectWinning
              justifyContent="space-between"
              alignItems="center"
              position="absolute"
              className={`${hasEnteredDown ? 'card-enter-postition-down' : ''}`}
            >
              <LogoOpx width={40} height={40} />
              <CollectWinningsButton
                hasClaimed={false}
                variant="primary"
                px="0"
                price={price || 0}
                style={{ fontWeight: 700 }}
              >
                {t('Collect Winnings')}
              </CollectWinningsButton>
              {/* <CollectWinningButton variant="success" px="0" price={price}>
                Collect Winnings
              </CollectWinningButton> */}
            </CollectWinning>
          )}
          <MultiplierArrow
            isExpired
            betAmount={betAmount}
            multiplier={bullMultiplier}
            isActive={betPosition === BetPosition.BULL}
            hasEntered={hasEnteredUp}
            hasClaimed={hasClaimedUp}
            isHouse={betPosition === BetPosition.HOUSE}
          />
          <RoundResult round={round} hasFailed={hasRoundFailed} />
          <MultiplierArrow
            betAmount={betAmount}
            multiplier={bearMultiplier}
            betPosition={BetPosition.BEAR}
            isActive={betPosition === BetPosition.BEAR}
            hasEntered={hasEnteredDown}
            hasClaimed={hasClaimedDown}
            isHouse={betPosition === BetPosition.HOUSE}
          />
        </CardBody>
      </StyledExpiredRoundCard>
      {/* <CollectWinningsOverlay epoch={epoch} isBottom={hasEnteredDown} /> */}
    </Box>
  )
}

export default ExpiredRoundCard
