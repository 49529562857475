import { Button, ButtonProps, useModal } from '@pancakeswap/uikit'
import useLocalDispatch from 'contexts/LocalRedux/useLocalDispatch'
import { AnyIfEmpty } from 'react-redux'
import { useCollectWinningModalProps } from 'state/predictions/hooks'
import { useConfig } from '../context/ConfigProvider'
import CollectRoundWinningsModal from './CollectRoundWinningsModal'

interface CollectWinningsButtonProps extends ButtonProps {
  hasClaimed: boolean
  price?: any
  onSuccess?: () => Promise<void>
}

const CollectWinningsButton: React.FC<React.PropsWithChildren<CollectWinningsButtonProps>> = ({
  hasClaimed,
  onSuccess,
  price,
  children,
  ...props
}) => {
  const { history, isLoadingHistory } = useCollectWinningModalProps()
  const dispatch = useLocalDispatch()
  const { address: predictionsAddress, token } = useConfig()
  const handleClickButton = (event) => {
    event.stopPropagation()
    onPresentCollectWinningsModal()
  }
  const [onPresentCollectWinningsModal] = useModal(
    <CollectRoundWinningsModal
      price={price}
      dispatch={dispatch}
      history={history}
      isLoadingHistory={isLoadingHistory}
      onSuccess={onSuccess}
      predictionsAddress={predictionsAddress}
      token={token}
    />,
    false,
    true,
    'CollectRoundWinningsModal',
  )

  return (
    <Button onClick={handleClickButton} disabled={hasClaimed} {...props}>
      {children}
    </Button>
  )
}

export default CollectWinningsButton
