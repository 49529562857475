import { useMemo, memo } from 'react'
import CountUp from 'react-countup'
import { Text } from '@pancakeswap/uikit'
import { formatBigNumberToFixed } from 'utils/formatBalance'
import styled from 'styled-components'
import { BigNumber } from '@ethersproject/bignumber'

interface LabelPriceProps {
  price: BigNumber
}

const LabelPrice: React.FC<React.PropsWithChildren<LabelPriceProps>> = ({ price }) => {
  const priceAsNumber = useMemo(() => parseFloat(formatBigNumberToFixed(price, 4, 8)), [price])

  if (!Number.isFinite(priceAsNumber)) {
    return null
  }

  return (
    <CountUp start={0} preserveValue delay={0} end={priceAsNumber} prefix="$" decimals={4} duration={1}>
      {({ countUpRef }) => (
        <Text fontSize="16px" fontWeight={700}>
          <span ref={countUpRef} />
        </Text>
      )}
    </CountUp>
  )
}

export default memo(LabelPrice)
