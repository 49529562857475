import React from 'react'
import styled from 'styled-components'
import { Collapse } from 'antd'
import { Text, Button, Box, Flex, useTooltip } from '@pancakeswap/uikit'
import { Bet } from 'state/types'
import { useTranslation } from '@pancakeswap/localization'
import useLocalDispatch from 'contexts/LocalRedux/useLocalDispatch'
import { useGetCurrentHistoryPage, useGetHasHistoryLoaded, useGetIsFetchingHistory } from 'state/predictions/hooks'
import { useConfig } from 'views/Predictions/context/ConfigProvider'
import { fetchNodeHistory } from 'state/predictions'
import orderBy from 'lodash/orderBy'
import { useAccount } from 'wagmi'
import V1ClaimCheck from '../../v1/V1ClaimCheck'
import HistoricalBet from '../HistoricalBet'

const { Panel } = Collapse

const StyledHistory = styled.div`
  .ant-collapse {
    border: none !important;

    .ant-collapse-item {
      border: none;

      .ant-collapse-header {
        align-items: center !important;
      }

      & + .ant-collapse-item {
        border-top: 1px solid ${({ theme }) => theme.colors.white10};
      }

      .ant-collapse-content {
        border: none;

        .ant-collapse-content-box {
          background: ${({ theme }) => theme.colors.backgroundAlt};
          padding: 24px;
        }
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-expand-icon svg {
        transform: rotate(180deg);
      }
    }
  }
`

interface RoundsTabProps {
  hasBetHistory: boolean
  bets: Bet[]
}

const AllFilter: React.FC<React.PropsWithChildren<RoundsTabProps>> = ({ hasBetHistory, bets }) => {
  const { t } = useTranslation()
  const dispatch = useLocalDispatch()
  const { address: account } = useAccount()
  const hasHistoryLoaded = useGetHasHistoryLoaded()
  const currentHistoryPage = useGetCurrentHistoryPage()
  const isFetchingHistory = useGetIsFetchingHistory()
  const { token } = useConfig()
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t('Includes your original position and your winnings, minus the 5% fee'),
    {
      placement: 'bottom',
    },
  )

  const handleClick = () => {
    dispatch(fetchNodeHistory({ account, page: currentHistoryPage + 1 }))
  }

  const v1Claim = token.symbol === 'BNB' ? <V1ClaimCheck /> : null

  return (
    <StyledHistory>
      {v1Claim}
      {orderBy(bets, ['round.epoch'], ['desc']).map((bet) => (
        <HistoricalBet key={bet.round.epoch} bet={bet} />
      ))}
      {hasBetHistory && !hasHistoryLoaded && (
        <Flex alignItems="center" justifyContent="center" py="24px">
          <Button variant="secondary" scale="sm" onClick={handleClick} disabled={isFetchingHistory}>
            {t('View More')}
          </Button>
        </Flex>
      )}
    </StyledHistory>
  )
}

export default AllFilter
