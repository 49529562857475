import { useMemo } from 'react'
import { useWeb3React } from '@pancakeswap/wagmi'
import { Box, Flex, Text, PrizeIcon, BlockIcon, LinkExternal, useTooltip, InfoIcon } from '@pancakeswap/uikit'
import styled from 'styled-components'
import useLocalDispatch from 'contexts/LocalRedux/useLocalDispatch'
import { useTranslation } from '@pancakeswap/localization'
import { REWARD_RATE } from 'state/predictions/config'
import { Bet, BetPosition } from 'state/types'
import { fetchLedgerData, markAsCollected } from 'state/predictions'
import { Result } from 'state/predictions/helpers'
import { useGetIsClaimable, useGetPredictionsTreasuryFee } from 'state/predictions/hooks'
import { getBlockExploreLink } from 'utils'
import { useConfig } from 'views/Predictions/context/ConfigProvider'

import Web3 from 'web3'
import usePollOraclePrice from 'views/Predictions/hooks/usePollOraclePrice'
import useIsRefundable from '../../hooks/useIsRefundable'
import { formatBnb, getNetPayout } from './helpers'
import CollectWinningsButton from '../CollectWinningsButton'
import PositionTag from '../PositionTag'
import ReclaimPositionButton from '../ReclaimPositionButton'

interface BetResultProps {
  bet: Bet
  result: Result
}

const StyledButton = styled(CollectWinningsButton)`
  border-radius: 8px !important;
  background: ${({ theme }) => theme.colors.success};
  height: 37px !important;
  color: black;
  font-size: 14px;
  font-weight: 700;
`

const StyledBetResult = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.white10};
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 12px 16px;
`

const Divider = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.white10};
  margin: 8px 0 16px;
`

const BetResult: React.FC<React.PropsWithChildren<BetResultProps>> = ({ bet, result }) => {
  const { t } = useTranslation()
  const treasuryFee = useGetPredictionsTreasuryFee()
  const dispatch = useLocalDispatch()
  const { account } = useWeb3React()
  const { isRefundable } = useIsRefundable(bet.round.epoch)
  const canClaim = useGetIsClaimable(bet.round.epoch)
  const { token, displayedDecimals } = useConfig()
  const { price } = usePollOraclePrice()
  const bnbBusdPrice = Number(Web3.utils.fromWei(price.toString())) * 10e9

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    <Text as="p">
      {t('Includes your original position and your winnings, minus the %fee% fee.', { fee: `${treasuryFee / 100}%` })}
    </Text>,
    { placement: 'bottom' },
  )

  const isWinner = result === Result.WIN

  // Winners get the payout, otherwise the claim what they put it if it was canceled
  const payout = isWinner ? getNetPayout(bet, REWARD_RATE) : bet.amount
  const totalPayout = bnbBusdPrice * payout
  // const totalPayout = payout * (tokenInfo?.[0]?.OPX?.price || 0)
  const returned = payout + bet.amount

  const isLoss = !isWinner || (isWinner && payout < 0)

  const headerColor = useMemo(() => {
    switch (result) {
      case Result.WIN:
        return 'warning'
      case Result.LOSE:
        return 'white60'
      case Result.CANCELED:
        return 'white60'
      case Result.HOUSE:
        return 'white60'
      default:
        return 'text'
    }
  }, [result])

  const headerText = useMemo(() => {
    switch (result) {
      case Result.WIN:
        return t('Win')
      case Result.LOSE:
        return t('Lose')
      case Result.CANCELED:
        return t('Cancelled')
      case Result.HOUSE:
        return <span style={{ color: '#ffffff' }}>{t('To Treasury')}</span>
      default:
        return ''
    }
  }, [t, result])

  const headerIcon = useMemo(() => {
    switch (result) {
      case Result.WIN:
        return <PrizeIcon color={headerColor} />
      case Result.LOSE:
      case Result.CANCELED:
        return <BlockIcon color={headerColor} />
      default:
        return null
    }
  }, [result, headerColor])

  const resultColor = useMemo(() => {
    if (result === Result.CANCELED || result === Result.HOUSE) {
      return 'text'
    }

    if (isLoss) return 'failure'
    return 'success'
  }, [isLoss, result])

  const handleSuccess = async () => {
    // We have to mark the bet as claimed immediately because it does not update fast enough
    dispatch(markAsCollected({ [bet.round.epoch]: true }))
    dispatch(fetchLedgerData({ account, epochs: [bet.round.epoch] }))
  }

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Text fontWeight={700} lineHeight="140%">
          {t('Your History')}
        </Text>
        <Flex alignItems="center">
          <Text as="h3" color={headerColor} textTransform="uppercase" fontWeight={700} mr="4px">
            {headerText}
          </Text>
          {headerIcon}
        </Flex>
      </Flex>
      <StyledBetResult>
        {result === Result.WIN && canClaim && (
          <StyledButton hasClaimed={!canClaim} width="100%" mb="16px" onSuccess={handleSuccess} price={price || 0}>
            {bet.claimed ? t('Already Collected') : t('Collect Winnings')}
          </StyledButton>
        )}
        {bet.claimed && bet.claimedHash && (
          <Flex justifyContent="center">
            <LinkExternal href={getBlockExploreLink(bet.claimedHash, 'transaction')} mb="16px">
              {t('View on BscScan')}
            </LinkExternal>
          </Flex>
        )}
        {result === Result.CANCELED && isRefundable && (
          <ReclaimPositionButton
            epoch={bet.round.epoch}
            width="100%"
            mb="16px"
            style={{ background: '#03F5AE', color: '#060303', height: '37px' }}
          />
        )}
        <Flex alignItems="center" justifyContent="space-between" mb="16px">
          <Text color="white60">{t('Your direction')}</Text>
          <PositionTag betPosition={bet.position} style={{ height: '37px' }}>
            {bet.position === BetPosition.BULL ? t('Up') : t('Down')}
          </PositionTag>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mb="16px">
          <Text color="white60">{t('Your position')}</Text>
          <Text fontWeight={700}>{`${formatBnb(bet.amount, 2)} FTM`}</Text>
        </Flex>
        <Flex alignItems="start" justifyContent="space-between">
          <Text color="white60">{isWinner ? t('Your PnL') : t('Your result')}</Text>
          <Box style={{ textAlign: 'right' }}>
            <Text fontWeight={700} color={resultColor}>{`${isLoss ? '-' : '+'}${formatBnb(
              Math.abs(payout),
              2,
            )} FTM`}</Text>
            <Text fontSize="12px" color="white60">
              {/* {`~ ${isLoss ? '-' : ''}$${Math.abs(totalPayout).toFixed(2)}`} */}
              {`$${Math.abs(totalPayout).toFixed(2)}`}
            </Text>
          </Box>
        </Flex>
        {isWinner && (
          <>
            <Divider />
            <Flex alignItems="start" justifyContent="space-between">
              <Text fontSize="14px" color="white60">
                {t('Amount to collect')}
              </Text>
              <Flex justifyContent="end">
                <Text fontSize="14px" fontWeight={700} color="white">{`${formatBnb(returned, 2)} FTM`}</Text>
                <span ref={targetRef}>
                  <InfoIcon color="white" ml="4px" />
                </span>
              </Flex>
              {tooltipVisible && tooltip}
            </Flex>
          </>
        )}
      </StyledBetResult>
    </>
  )
}

export default BetResult
