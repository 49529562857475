import styled from 'styled-components'
import { Flex } from '@pancakeswap/uikit'

const FlexRow = styled(Flex).attrs({ alignItems: 'center' })`
  flex: 1;
  padding: 0 250px;

  @media screen and (max-width: 1700px) {
    padding: 16px 240px;
  }

  @media screen and (max-width: 1600px) {
    padding: 16px 215px;
  }

  @media screen and (max-width: 1500px) {
    padding: 16px 192px;
  }

  @media screen and (max-width: 1470px) {
    padding: 24px 30px;
  }

  @media screen and (max-width: 1366px) {
    padding: 24px 150px;
  }

  @media screen and (max-width: 1365px) {
    padding: 24px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export default FlexRow
