import React from 'react'
import styled from 'styled-components'
import { Collapse } from 'antd'
import {
  Text,
  Button,
  Box,
  Flex,
  ArrowDropDownFooter,
  WinningCupIcon,
  Heading,
  ArrowDownIcon,
  CancelledIcon,
} from '@pancakeswap/uikit'

import { Bet, HistoryFilter } from 'state/types'
import { useTranslation } from '@pancakeswap/localization'
import useLocalDispatch from 'contexts/LocalRedux/useLocalDispatch'
import {
  useGetCurrentHistoryPage,
  useGetHasHistoryLoaded,
  useGetHistoryFilter,
  useGetIsFetchingHistory,
} from 'state/predictions/hooks'
import { useConfig } from 'views/Predictions/context/ConfigProvider'
import { fetchNodeHistory } from 'state/predictions'
// eslint-disable-next-line lodash/import-scope
import { orderBy } from 'lodash'
import HistoricalBet from '../HistoricalBet'
import V1ClaimCheck from '../../v1/V1ClaimCheck'
import { useWeb3React } from '../../../../../../packages/wagmi/src/useWeb3React'

const { Panel } = Collapse

const ResultText = styled(Text)`
  color: ${({ theme }) => theme.colors.success};
`

const CollectButton = styled(Button)`
  padding: 8px;
  font-size: 14px;
  font-weight: 700;
  margin-left: auto;
  border-radius: ${({ rounded }) => rounded || '8px'};
  color: ${({ textColor }) => textColor || 'white'};
  width: ${({ width }) => width || 'unset'};
  max-height: ${({ maxHeight }) => maxHeight || 'unset'};
`

const StyledHistoryBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.white10};
  border-radius: 24px;
`

const StyledHistory = styled.div`
  .ant-collapse {
    border: none !important;

    .ant-collapse-item {
      border: none;

      .ant-collapse-header {
        align-items: center !important;
      }

      & + .ant-collapse-item {
        border-top: 1px solid ${({ theme }) => theme.colors.white10};
      }

      .ant-collapse-content {
        border: none;

        .ant-collapse-content-box {
          background: ${({ theme }) => theme.colors.backgroundAlt};
          padding: 24px;
        }
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-expand-icon svg {
        transform: rotate(180deg);
      }
    }
  }
`

interface RoundsTabProps {
  hasBetHistory: boolean
  bets: Bet[]
}

const UnCollectedFilter: React.FC<React.PropsWithChildren<RoundsTabProps>> = ({ hasBetHistory, bets }) => {
  const { t } = useTranslation()
  const dispatch = useLocalDispatch()
  const { account } = useWeb3React()
  const hasHistoryLoaded = useGetHasHistoryLoaded()
  const currentHistoryPage = useGetCurrentHistoryPage()
  const isFetchingHistory = useGetIsFetchingHistory()
  const { token } = useConfig()

  const handleClick = () => {
    dispatch(fetchNodeHistory({ account, page: currentHistoryPage + 1 }))
  }

  const v1Claim = token.symbol === 'BNB' ? <V1ClaimCheck /> : null
  const currentFilter = useGetHistoryFilter()

  switch (currentFilter) {
    case HistoryFilter.ALL:
      break
    case HistoryFilter.COLLECTED:
      break
    case HistoryFilter.UNCOLLECTED:
      break
    default:
      break
  }

  return (
    <StyledHistory>
      {v1Claim}
      {orderBy(bets, ['round.epoch'], ['desc']).map((bet) => (
        <HistoricalBet key={bet.round.epoch} bet={bet} />
      ))}
    </StyledHistory>
  )
}

export default UnCollectedFilter
