import { ArrowLeft, Box, Button, Text } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useLocalDispatch from 'contexts/LocalRedux/useLocalDispatch'
import { setHistoryPaneState } from 'state/predictions'
import { PredictionSupportedSymbol } from 'state/types'
import { useRouter } from 'next/router'
import { useConfig } from 'views/Predictions/context/ConfigProvider'
import Notification from './Notification'

const PauseNotification = () => {
  const { t } = useTranslation()
  const dispatch = useLocalDispatch()

  const handleOpenHistory = () => {
    dispatch(setHistoryPaneState(true))
  }

  const router = useRouter()
  const { token } = useConfig()

  return (
    <Notification className="noti-pause" title={null}>
      <Box mb="32px">
        {/* <Text
          className="title-pause"
          as="p"
        >
        <ArrowLeft />
        </Text> */}
        {t('Markets Paused')}
      </Box>

      <Box mb="12px">
        <Text as="p">{t('Prediction markets have been paused due to an error.')}</Text>
        <Text as="p">{t('All open positions have been cancelled.')}</Text>
        <Text as="p">
          {t('You can reclaim any funds entered into existing positions via the History tab on this page.')}
        </Text>
      </Box>
      <Button variant="primary" width="100%" onClick={handleOpenHistory}>
        {t('Show History')}
      </Button>
    </Notification>
  )
}

export default PauseNotification
