import { useTranslation } from '@pancakeswap/localization'
import { Button, ButtonProps } from '@pancakeswap/uikit'
import { ToastDescriptionWithTx } from 'components/Toast'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import useCatchTxError from 'hooks/useCatchTxError'
import { usePredictionsContract } from 'hooks/useContract'
import { ReactNode } from 'react'
import { helperToast } from 'utils/helperToast'
import { useConfig } from '../context/ConfigProvider'

interface ReclaimPositionButtonProps extends ButtonProps {
  epoch: number
  onSuccess?: () => Promise<void>
  children?: ReactNode
}

const ReclaimPositionButton: React.FC<React.PropsWithChildren<ReclaimPositionButtonProps>> = ({
  epoch,
  onSuccess,
  children,
  ...props
}) => {
  const { t } = useTranslation()
  const { address: predictionsAddress } = useConfig()
  const { token } = useConfig()
  const predictionsContract = usePredictionsContract(predictionsAddress, token.symbol)
  const { callWithGasPrice } = useCallWithGasPrice()
  const { fetchWithCatchTxError, loading: isPendingTx } = useCatchTxError()

  const handleReclaim = async () => {
    const receipt = await fetchWithCatchTxError(() => {
      return callWithGasPrice(predictionsContract, 'claim', [[epoch]])
    })
    if (receipt?.status) {
      await onSuccess?.()
      helperToast.success(
        <>
          <span>Position reclaimed!</span>
          <ToastDescriptionWithTx txHash={receipt.transactionHash} />
        </>,
        { hideTitle: true },
      )
    }
  }

  return (
    <Button mb="16px" variant="primary" onClick={handleReclaim} isLoading={isPendingTx} {...props}>
      {!isPendingTx ? children || t('Reclaim Position') : t('Reclaiming...')}
    </Button>
  )
}

export default ReclaimPositionButton
