import { Card, CardBody, Text, Flex, BlockIcon, LinkExternal, CloseIcon } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { NodeRound, BetPosition } from 'state/types'
import useTheme from 'hooks/useTheme'
import useLedger from 'views/Predictions/hooks/useLedger'
import ReclaimPositionButton from '../ReclaimPositionButton'
import useIsRefundable from '../../hooks/useIsRefundable'
import { RoundResultBox } from '../RoundResult'
import MultiplierArrow from './MultiplierArrow'
import CardHeader, { getBorderBackground } from './CardHeader'

interface CanceledRoundCardProps {
  round: NodeRound
}

const CanceledRoundCard: React.FC<React.PropsWithChildren<CanceledRoundCardProps>> = ({ round }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { setIsRefundable } = useIsRefundable(round.epoch)
  const { ledger } = useLedger(round.epoch)
  const { epoch } = round

  const handleSuccess = async () => {
    setIsRefundable(false)
  }

  return (
    <Card borderBackground={getBorderBackground(theme, 'canceled')}>
      <CardHeader
        status="canceled"
        icon={<CloseIcon width="24px" color="textMain" mr={2} />}
        title={t('Cancelled')}
        epoch={round.epoch}
      />
      <CardBody p="16px">
        <MultiplierArrow isDisabled />
        <RoundResultBox>
          <Flex flexDirection="column" alignItems="center">
            <Text bold color="text" mb="16px">
              {t('Round Cancelled')}
            </Text>
            {ledger.amount.gt(0) && (
              <ReclaimPositionButton disabled={ledger.claimed} epoch={epoch} onSuccess={handleSuccess} width="100%" />
            )}
            <LinkExternal
              fontWeight={500}
              color="success"
              href="https://docs.mummy.finance/prediction/prediction-faq#market-pauses"
              external
            >
              {t('Learn More')}
            </LinkExternal>
          </Flex>
        </RoundResultBox>
        <MultiplierArrow betPosition={BetPosition.BEAR} isDisabled />
      </CardBody>
    </Card>
  )
}

export default CanceledRoundCard
